.single-cart {
  box-shadow: 0px 6px 10px rgba(255, 115, 58, 0.25);
}

.list1 {
  list-style-type: circle;
}

.list2 {
  list-style-type: disc;
}

.transition-all-03 {
  transition: all 0.3s ease-in-out;
}