@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./fonts/Inter/static/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "product-sans";
  src: local("product-sans"),
    url("./fonts/product-sans/Product\ Sans\ Regular.ttf") format("truetype");
}

@font-face {
  font-family: "den-pro";
  src: local("den-pro"),
    url("./fonts/637-font.otf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
