.footerl {
  background-image: url(/public/images/home/Footer.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.icon {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.iconF1 {
  background-image: url(/public/images/home/FooterIcons/fbIconHover.svg);
}

.iconF2 {
  background-image: url(/public/images/home/FooterIcons/instaIconHover.svg);
  margin-left: 5px;
}

/* .iconF1:hover,
.iconF1:focus {
  background-image: url(/public/images/home/FooterIcons/fbIconHover.svg);
}

.iconF2:hover,
.iconF2:focus {
  background-image: url(/public/images/home/FooterIcons/instaIconHover.svg);
} */
