.rowMain {
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(255, 115, 58, 0.25);
  border-radius: 20px;
}

.col1Contact {
  background-image: url(/public/images/contactus/contactUsBG.JPG);
  background-repeat: no-repeat;
  background-size: cover;
}

.iconC1 {
  background-image: url(/public/images/home/FooterIcons/fbIconHover.svg);
}

.iconC2 {
  background-image: url(/public/images/home/FooterIcons/instaIconHover.svg);
  margin-left: 5px;
}

.iconC1:hover,
.iconC1:focus {
  background-image: url(/public/images/contactus/fbIconContactHover.svg);
}

.iconC2:hover,
.iconC2:focus {
  background-image: url(/public/images/contactus/instaIconContactHover.svg);
}


.co1 .btn-primary {
  background-color: #ff733a !important;
  border: #ff733a !important;
}