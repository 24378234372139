.btn-close {
  background-color: #ff733a;
}

.list-group-item.active {
  border-bottom: 4px solid #ff733a;
}

.rdbs .form-check-input {
  margin: 0;
}

.col1Show {
  display: block;
}

.col1Hidden {
  display: none;
}

.swiperSlide {
  width: auto;
  cursor: pointer;
}

.swiperContainer {
  width: 80%;
  height: auto;
  padding-bottom: 15px;
}

.swiper-scrollbar-drag {
  background: #ff733a;
}
.swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  position: relative !important;
  z-index: 50;
  height: 5px;
  width: 85% !important;
  margin: auto !important;
}

.form-check-label {
  color: black;
  font-weight: 500;
  cursor: pointer;
}

.form-check-input {
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.canvas-container {
  display: flex;
  align-items: stretch;
}
.canvas-container .upper-canvas {
  position: relative !important;
}
.cropper-container {
  width: auto !important;
}

.step2Cont .swiper-button-next {
  content: url(/public/images/shopcards/arrowRight.svg) !important;
  height: 25px !important;
}
.step2Cont .swiper-button-prev {
  content: url(/public/images/shopcards/arrowLeft.svg) !important;
  height: 25px !important;
}
.divTransition {
  -webkit-animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.img1Step1 {
  width: 550px;
  height: 550px;
  object-fit: cover;
  margin: auto;
}

@media (min-width: 995px) and (max-width: 1190px) {
  .img1Step1 {
    width: 250px;
    height: 550px;
    object-fit: cover;
    /* margin: auto; */
  }
}

@media (min-width: 1190px) and (max-width: 1260px) {
  .img1Step1 {
    width: 350px;
    height: 550px;
    object-fit: cover;
    /* margin: auto; */
  }
}

@media (min-width: 1260px) and (max-width: 1360px) {
  .img1Step1 {
    width: 450px;
    height: 550px;
    object-fit: cover;
    /* margin: auto; */
  }
}

@media (min-width: 1800px) {
  .swiperContainer {
    width: 50%;
    height: auto;
    padding-bottom: 15px;
  }
}
