.App {
  /* background: #F5F5F5; */
  font-family: product-sans;
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;
  overflow-x: hidden;
  touch-action: manipulation;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: gainsboro;
}

::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

a {
  text-decoration: none !important;
}

.navbar-toggler {
  border: none !important;
}
