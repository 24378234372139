.swiper {
  width: 100%;
  height: 100%;
  cursor: grab;
}

.mySwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
}

.mySwiper .swiper-slide img {
  display: block;
  object-fit: cover;
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  display: block;
  opacity: 0;
  background-color: transparent !important;
}

.mySwiper .swiper-button-next:hover,
.mySwiper .swiper-button-prev:hover {
  opacity: 1;
  transition-delay: 0s;
  background-color: #FF733A;
}

.swiper-button-next {
  content: url(/public/images/home/right\ arrow.svg);
}

.swiper-button-prev {
  content: url(/public/images/home/left\ arrow.svg);
}

@media (max-width: 670px) {
  .mySwiper .swiper-button-next,
  .mySwiper .swiper-button-prev {
    display: none !important;
  }
}

.swiper-pagination-bullet-active {
  background-color: #a04723 !important;
}
.swiper1 a:hover {
  color: white;
  opacity: 0.9;
}
span {
  display: block;
}
.no-effect {
  opacity: 0;
}
.text-effect1 {
  position: relative;
  border-left: 2px solid #a04723;
  padding-left: 10px;
  transform: scaleY(0);
  animation: scaleUpBorder 1s forwards cubic-bezier(0.85, 0, 0.15, 1);
}
.text-effect1 .text-wrapper {
  transform: translateX(-3%);
  opacity: 0;
  animation: slideTextToRight 1s 0.5s forwards cubic-bezier(0.85, 0, 0.15, 1);
}
@keyframes scaleUpBorder {
  to {
    transform: scaleY(1);
  }
}

@keyframes slideTextToRight {
  to {
    opacity: 0.9;
    transform: translateX(0%);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}