@media (max-width: 390px) {
  .txts1 {
    transform: translateY(1.3rem) translateX(-1.2rem);
  }
  .txts2 {
    transform: translateY(1.8rem) translateX(-1.5rem);
  }
  .txts3 {
    transform: translateY(2rem) translateX(-2.3rem);
  }
  .txts4 {
    transform: translateY(2rem) translateX(-3.5rem);
  }
}

@media (min-width: 390px) and (max-width: 430px) {
  .txts1 {
    transform: translateY(1.9rem) translateX(-1.4rem);
  }
  .txts2 {
    transform: translateY(2.4rem) translateX(-1.6rem);
  }
  .txts3 {
    transform: translateY(2.7rem) translateX(-2.4rem);
  }
  .txts4 {
    transform: translateY(2.7rem) translateX(-3.6rem);
  }
}

@media (min-width: 430px) and (max-width: 470px) {
  .txts1 {
    transform: translateY(2.4rem) translateX(-1.4rem);
  }
  .txts2 {
    transform: translateY(3.1rem) translateX(-1.7rem);
  }
  .txts3 {
    transform: translateY(3.6rem) translateX(-2.5rem);
  }
  .txts4 {
    transform: translateY(3.9rem) translateX(-3.9rem);
  }
}

@media (min-width: 470px) and (max-width: 510px) {
  .txts1 {
    transform: translateY(2.6rem) translateX(-1.4rem);
  }
  .txts2 {
    transform: translateY(3rem) translateX(-1.7rem);
  }
  .txts3 {
    transform: translateY(3.5rem) translateX(-2.6rem);
  }
  .txts4 {
    transform: translateY(3.5rem) translateX(-4rem);
  }
}

@media (min-width: 510px) and (max-width: 550px) {
  .txts1 {
    transform: translateY(3.3rem) translateX(-1.4rem);
  }
  .txts2 {
    transform: translateY(3.9rem) translateX(-1.7rem);
  }
  .txts3 {
    transform: translateY(4.4rem) translateX(-2.7rem);
  }
  .txts4 {
    transform: translateY(4.9rem) translateX(-4.5rem);
  }
}

@media (min-width: 550px) and (max-width: 590px) {
  .txts1 {
    transform: translateY(3.3rem) translateX(-1.5rem);
  }
  .txts2 {
    transform: translateY(4rem) translateX(-1.8rem);
  }
  .txts3 {
    transform: translateY(4.6rem) translateX(-2.8rem);
  }
  .txts4 {
    transform: translateY(5rem) translateX(-4.6rem);
  }
}
@media (min-width: 590px) and (max-width: 650px) {
  .txts1 {
    transform: translateY(3.3rem) translateX(-1.5rem);
  }
  .txts2 {
    transform: translateY(4.1rem) translateX(-1.8rem);
  }
  .txts3 {
    transform: translateY(4.6rem) translateX(-2.8rem);
  }
  .txts4 {
    transform: translateY(5rem) translateX(-4.6rem);
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .txts1 {
    transform: translateY(9.6rem) translateX(-1.6rem);
  }
  .txts2 {
    transform: translateY(11.4rem) translateX(-2rem);
  }
  .txts3 {
    transform: translateY(13.4rem) translateX(-4rem);
  }
  .txts4 {
    transform: translateY(15rem) translateX(-7.4rem);
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .txts1 {
    transform: translateY(6rem) translateX(-1.6rem);
  }
  .txts2 {
    transform: translateY(7.5rem) translateX(-2rem);
  }
  .txts3 {
    transform: translateY(9rem) translateX(-4rem);
  }
  .txts4 {
    transform: translateY(9rem) translateX(-7rem);
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .txts1 {
    transform: translateY(9rem) translateX(-1.6rem);
  }
  .txts2 {
    transform: translateY(12rem) translateX(-2.1rem);
  }
  .txts3 {
    transform: translateY(13.5rem) translateX(-4.5rem);
  }
  .txts4 {
    transform: translateY(15rem) translateX(-8.5rem);
  }
}

@media (min-width: 1400px) {
  .txts1 {
    transform: translateY(12rem) translateX(-1.6rem);
  }
  .txts2 {
    transform: translateY(15rem) translateX(-2rem);
  }
  .txts3 {
    transform: translateY(18rem) translateX(-5.2rem);
  }
  .txts4 {
    transform: translateY(20.5rem) translateX(-10rem);
  }
}
