.slick-dots li.slick-active button:before {
  color: #ff733a !important;
}

.slick-prev:before {
  color: #e7a68d !important;
}

.slick-next:before {
  color: #e7a68d !important;
}

@media (max-width: 430px) {
  .slick-next:before,
  .slick-prev:before {
    display: none;
  }
}
