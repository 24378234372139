@media (max-width: 390px) {
  .txt1 {
    transform: translateY(2.7rem) translateX(1.2rem);
  }
  .txt2 {
    transform: translateY(2.9rem) translateX(1.5rem);
  }
  .txt3 {
    transform: translateY(2.9rem) translateX(2.3rem);
  }
  .txt4 {
    transform: translateY(2.8rem) translateX(3.5rem);
  }
}

@media (min-width: 390px) and (max-width: 430px) {
  .txt1 {
    transform: translateY(2.8rem) translateX(1.4rem);
  }
  .txt2 {
    transform: translateY(3.1rem) translateX(1.7rem);
  }
  .txt3 {
    transform: translateY(3.4rem) translateX(2.4rem);
  }
  .txt4 {
    transform: translateY(3.5rem) translateX(3.6rem);
  }
}

@media (min-width: 430px) and (max-width: 470px) {
  .txt1 {
    transform: translateY(2.8rem) translateX(1.4rem);
  }
  .txt2 {
    transform: translateY(3.4rem) translateX(1.7rem);
  }
  .txt3 {
    transform: translateY(3.9rem) translateX(2.5rem);
  }
  .txt4 {
    transform: translateY(4rem) translateX(3.9rem);
  }
}

@media (min-width: 470px) and (max-width: 510px) {
  .txt1 {
    transform: translateY(3.3rem) translateX(1.4rem);
  }
  .txt2 {
    transform: translateY(3.7rem) translateX(1.7rem);
  }
  .txt3 {
    transform: translateY(4.2rem) translateX(2.6rem);
  }
  .txt4 {
    transform: translateY(4.4rem) translateX(4rem);
  }
}

@media (min-width: 510px) and (max-width: 550px) {
  .txt1 {
    transform: translateY(3.3rem) translateX(1.4rem);
  }
  .txt2 {
    transform: translateY(3.9rem) translateX(1.7rem);
  }
  .txt3 {
    transform: translateY(4.4rem) translateX(2.7rem);
  }
  .txt4 {
    transform: translateY(4.9rem) translateX(4.5rem);
  }
}

@media (min-width: 550px) and (max-width: 590px) {
  .txt1 {
    transform: translateY(3.3rem) translateX(1.5rem);
  }
  .txt2 {
    transform: translateY(4rem) translateX(1.8rem);
  }
  .txt3 {
    transform: translateY(4.6rem) translateX(2.8rem);
  }
  .txt4 {
    transform: translateY(5rem) translateX(4.6rem);
  }
}
@media (min-width: 590px) and (max-width: 650px) {
  .txt1 {
    transform: translateY(3.3rem) translateX(1.5rem);
  }
  .txt2 {
    transform: translateY(4.1rem) translateX(1.8rem);
  }
  .txt3 {
    transform: translateY(4.6rem) translateX(2.8rem);
  }
  .txt4 {
    transform: translateY(5rem) translateX(4.6rem);
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .txt1 {
    transform: translateY(4.6rem) translateX(1.6rem);
  }
  .txt2 {
    transform: translateY(7rem) translateX(2rem);
  }
  .txt3 {
    transform: translateY(9rem) translateX(4rem);
  }
  .txt4 {
    transform: translateY(10.8rem) translateX(7rem);
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .txt1 {
    transform: translateY(6rem) translateX(1.6rem);
  }
  .txt2 {
    transform: translateY(7.5rem) translateX(2rem);
  }
  .txt3 {
    transform: translateY(9rem) translateX(4rem);
  }
  .txt4 {
    transform: translateY(9rem) translateX(7rem);
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .txt1 {
    transform: translateY(6rem) translateX(1.6rem);
  }
  .txt2 {
    transform: translateY(8.5rem) translateX(2.5rem);
  }
  .txt3 {
    transform: translateY(10.5rem) translateX(5rem);
  }
  .txt4 {
    transform: translateY(12rem) translateX(9rem);
  }
}

@media (min-width: 1400px) {
  .txt1 {
    transform: translateY(7rem) translateX(1.6rem);
  }
  .txt2 {
    transform: translateY(9.5rem) translateX(2.5rem);
  }
  .txt3 {
    transform: translateY(13rem) translateX(5.5rem);
  }
  .txt4 {
    transform: translateY(15rem) translateX(10rem);
  }
}