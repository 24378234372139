.loaderContainer {
    position: relative;
    min-height: 100vh;
  }
  
  .loaderOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loaderOverlay img {
    width: 30%;
    height: 30%;
    object-fit: contain !important;
    max-width: 100%;
    max-height: 100%;
  }