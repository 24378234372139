.shopCardsSection {
  background: url(/public/images/cart/Checkout_Bg.JPG);
  background-repeat: no-repeat;
  background-size: cover;
}

.shopCardsSection::-webkit-scrollbar {
  scrollbar-width: 0px !important;
}
.wizardCont {
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(255, 115, 58, 0.25);
  border-radius: 20px;
}
.nav-tabs .nav-link {
  border: none !important;
}
.step1 {
  background: linear-gradient(90deg, #ecccbe 0%, #ff733a 100%) !important;
  box-shadow: 6px 0px 10px rgba(237, 183, 160, 0.35) !important;
  border-radius: 20px 40px 40px 0px !important;
}
.step2 {
  background: linear-gradient(90deg, #ecccbe 0%, #ff733a 100%) !important;
  box-shadow: 6px 0px 10px rgba(237, 183, 160, 0.35) !important;
  border-radius: 20px 40px 40px 0px !important;
  border-top-left-radius: 0px !important;
  border-left-color: transparent !important;
  border-color: transparent !important;
}
.step3 {
  background: linear-gradient(90deg, #ecccbe 0%, #ff733a 100%) !important;
  box-shadow: 6px 0px 10px rgba(237, 183, 160, 0.35) !important;
  border-radius: 20px 40px 40px 0px !important;
  border-color: transparent !important;
  border-top-left-radius: 0px !important;
  border-left-color: transparent !important;
}
.step4 {
  background: linear-gradient(90deg, #ecccbe 0%, #ff733a 100%) !important;
  box-shadow: 6px 0px 10px rgba(237, 183, 160, 0.35) !important;
  border-radius: 20px 40px 40px 0px !important;
  border-color: transparent !important;
  border-top-left-radius: 0px !important;
  border-left-color: transparent !important;
}
.step5 {
  background: linear-gradient(90deg, #ecccbe 0%, #ff733a 100%) !important;
  box-shadow: 6px 0px 10px rgba(237, 183, 160, 0.35) !important;
  border-radius: 20px 40px 40px 0px !important;
  border-color: transparent !important;
  border-top-left-radius: 0px !important;
  border-left-color: transparent !important;
}
.step6 {
  background: linear-gradient(90deg, #ecccbe 0%, #ff733a 100%) !important;
  box-shadow: 6px 0px 10px rgba(255, 115, 58, 0.35) !important;
  border-color: transparent !important;
  border-top-left-radius: 0px !important;
  border-left-color: transparent !important;
  border-top-right-radius: 20px !important;
}

@media (max-width:1160px){
  .shopCardsSection .nav-link {
    padding: 0 !important;
  }
}