.quality {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  /* padding: 60px !important; */
  background-position: center !important;
}

@media (max-width: 460px) {
  .quality {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: left 20% top !important;
    padding: 0px !important;
  }
}

@media (min-width: 460px) and (max-width: 640px) {
  .quality {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: left 20% top !important;
    padding: 5px !important; 
  }
}

@media (min-width: 640px) and (max-width: 850px) {
    .quality {
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: left 20% top !important;
      padding: 10px !important;
    }
  }