@import url("https://use.fontawesome.com/releases/v5.15.0/css/all.css");
.accordion-button:not(.collapsed)::after {
  content: "\f068";
  font-weight: 900;
  font: bold;
  font-family: "Font Awesome 5 Free";
  color: #000000;
  background-image: none;
  border-bottom: none !important;
}

.accordion-button.collapsed::after {
  content: "\f067";
  font: bold;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  background-image: none;
}
.accordion-button {
  background: transparent !important;
}

.accordion-button:focus {
  box-shadow: none;
}
.accordion {
  background: transparent !important;
}
.accordion-item {
  background-color: transparent !important;
}

.faBg {
  background-image: url(/public/images/home/FAQbg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 0px 40px rgba(255, 115, 58, 0.25);
}
