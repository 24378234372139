.slider .owl-item.active.center .slider-card {
  transform: scale(1.15) !important;
  opacity: 1 !important;
  color: #fff !important;
  border-radius: 20px !important ;
  margin: 50px 0px 0px 0px !important;
}

.slider-card {
  background-color: #fff !important;
  margin: 50px 0px 0px 0px !important;
  transform: scale(0.9) !important;
  opacity: 0.6 !important;
  transition: all 0.3s !important;
}

.owl-dot.active span {
  background: #ff733a !important;
}
