.col1 {
  background-image: url(/public/images/home/bigOccasion/Left\ Half\ circle\ BG.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}

.col3 {
  background-image: url(/public/images/home/bigOccasion/Right\ Half\ circle\ BG.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
