.mySwiper2 {
  background: url(/public/images/home/BG.png);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
  margin-top: 2rem;
}

.mySwiper2 .swiper-button-prev {
  text-align: center;
  color: #cf5d2e;
  display: block !important;
}
.mySwiper2 .swiper-button-next {
  text-align: center;
  color: #cf5d2e;
  display: block !important;
}

.mySwiper2 .swiper-pagination-bullet-active {
  background-color: #ff733a !important;
  margin-top: 2rem !important;
}

.mySwiper2 .swiper-pagination {
  position: relative !important;
}

@media (max-width: 1000px) {
  .mySwiper2 .swiper-button-prev {
    display: none !important;
  }
  .mySwiper2 .swiper-button-next {
    display: none !important;
  }
}
