.fv {
  font-family: den-pro;
}

.btn--ripple {
  /* background: linear-gradient(90deg, #FF733A, #eb855c); */
  overflow: hidden;
}
.btn--ripple span {
  position: absolute;
  background: #fff;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  animation: rippleAnimation 1s linear infinite;
}
@keyframes rippleAnimation {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}
