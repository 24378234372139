.AU1 {
  background-image: url(/public/images/cart/Checkout_Bg.JPG);
  background-repeat: no-repeat;
  background-size: cover;
}
.cardContainer {
  position: relative !important;
  overflow: hidden !important;
  z-index: 1 !important;
}
.cardContainer::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s ease all;
}
.ImgClass {
  transition: 0.5s ease all;
  width: 100%;
  object-fit: cover;
}
.cardContainer:hover .ImgClass {
  transform: scale(1.1);
}
.cardContainer:hover::after {
  opacity: 0.2;
}

.AU2 {
  background-image: url(/public/images/aboutus/AUbg.PNG);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
