.form-check-input:checked {
  background-color: #ff733a !important;
  border-color: #ff733a !important;
}

.form-check-input:disabled {
  background-color: #bbb !important;
  border-color: #bbb !important;
}

.cont1 {
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(255, 115, 58, 0.25);
  border-radius: 25px !important;
}

.form2 {
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(255, 115, 58, 0.25);
  border-radius: 24px;
}

.form2 label {
  display: flex !important;
  text-align: left !important;
}

.contAll {
  background-image: url(/public/images/cart/Checkout_Bg.JPG);
  background-repeat: no-repeat;
  background-size: cover;
}

.ch1 .btn-primary {
  background-color: #ff733a !important;
  border: #ff733a !important;
}

.dropdown-menu .show {
  height: 20vh !important;
  overflow: scroll !important;
}
