.iconNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  width: 25px;
  height: 25px;
  padding-bottom: 0px !important;
}
.iconB1 {
  background-image: url(/public/images/navbar/basketIcon.svg);
  background-repeat: no-repeat;
}

.iconB2 {
  background-image: url(/public/images/navbar/basketIconLight.svg);
  background-repeat: no-repeat;
}
.iconP1 {
  background-image: url(/public/images/navbar/profileIcon.svg);
  background-repeat: no-repeat;
}

.iconP2 {
  background-image: url(/public/images/navbar/profileIconLihgt.svg);
  background-repeat: no-repeat;
}

.iconB1:hover,
.iconB1:focus,
.iconB2:hover,
.iconB2:focus {
  background-image: url(/public/images/navbar/basketHover.svg);
  background-repeat: no-repeat;
}

.iconP1:hover,
.iconP1:focus,
.iconP2:hover,
.iconP3:focus {
  background-image: url(/public/images/navbar/profileHover.svg);
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .iconB2 {
    background-image: url(/public/images/navbar/basketIcon.svg);
    background-repeat: no-repeat;
  }
  .iconP2 {
    background-image: url(/public/images/navbar/profileIcon.svg);
    background-repeat: no-repeat;
  }
}

.dropdown-toggle::after {
  color: white;
}

.fa-user:hover .dropdown-menu {
  display: block;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ff733a !important;
  color: #fff !important;
}

.nn .btn-close {
  background-color: transparent !important;
  /* background-image: url(/public/images/navbar/closex.png) !important; */
}

@media (max-width: 600px) {
  .offCanvasSmall {
    background-image: url(/public/images/nav_bg.PNG);
    background-repeat: no-repeat;
    background-size: cover;
  }
}
